// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.topBarImageAlignment {
    text-align: center;
}

body a:hover {
    color: $color-template;
}

.theme-light .form input,
.theme-light .form textarea {
    color: black;
}

.themeColorText {
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    // border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    display: none;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
    width: 100%
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
    color: white
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-white;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    font-size: 14px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
    background: $button-text-color;
    color: $button-bg-color;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
    height: 32px;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    background-color: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
    padding: 8px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $button-bg-color;
        border-color: $button-hover-color !important;
        color: $button-text-color !important;
    }
}

.p-button:enabled:hover,
.p-button:enabled:active {
    background-color: $button-hover-color;
    border-color: $button-hover-color ;
    color: $button-text-color;
}

.p-button.p-button-outlined {
    color: $button-bg-color;
}

.p-button.button-filter {
    padding: 8px 14px;
    font-size: small;
    height: 42px;
}

.p-button-sm.button-filter {
    padding: 15px;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title {
    text-transform: capitalize !important;
    font-size: 13px !important;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}


body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-template;
    border-color: $color-template
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: 500;
    color: $color-template;
    cursor: pointer;
}



.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}

.p-multiselect-label-container {
    height: 30px;
}

.p-autocomplete-input-token input {
    border: none
}

.logout_modal_width {
    max-width: 400px;
}

.call_action_column {
    text-align: 'center';
    text-decoration: underline;
    color: $color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row {
    cursor: initial !important;
}

.p-datatable-row:hover {
    background: transparent
}

.settings_form {
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}

.form .settings_form:focus,
.form .settings_form:active {
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,
.started_badge {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}

.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}

body .p-component {
    // font-family: Open Sans, Helvetica Neue, sans-serif;

    font-family: "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
    font-size: 14px !important;
}

.pagenumber-indicator {
    display: flex;
    height: 100%;
    align-items: center;
}

.rdw-editor-toolbar {
    img {
        height: 20px;
        width: 20px;
    }
}

.p-column-header-content:has(.p-checkbox) {
    justify-content: center;
}

.p-filter-column {
    text-align: center !important;
    padding: 0.40rem !important;

    .p-inputtext {
        padding: 0.45rem 0.75rem;
    }

    .p-column-filter-clear-button {
        display: none;
    }

    .p-multiselect-label {
        padding: 0.25rem 0.75rem;
        color: black;
    }

}

.p-autocomplete input {
    width: 100%;
}

.p-button {
    &:hover {
        background: $color-template !important;
        border-color: $color-template
    }
}

.ck-content {
    min-height: 30vh;
}

.p-button.p-button-icon-only {
    height: 3.2rem;
}

.p-autocomplete-multiple-container {
    width: 100% !important;
}

.p-resizable-column {
    padding: 5px !important;
    // font-weight: 600 !important;
    font-size: 14px !important;
}

//file upload css
.p-fileupload-file-badge {
    display: none !important;
}

.p-fileupload .p-fileupload-content {
    padding: 0px !important;
}

//css for buttons in the login component 
.scale {
    transform: scale(none);

    button {
        white-space: nowrap;
    }
}


.p-button.p-button-sm {
    justify-content: center !important;
}



.heading {
    font-size: 18px !important;
    font-weight: bolder !important;
    text-transform: none !important;
    color: black !important;
}

.p-datatable .p-column-header-content {
    justify-content: center !important;
}

.outline-button {
    border: 1px solid #0e4768 !important;
    background-color: transparent !important;
    color: #0e4768 !important;

}

.fa-lg {
    font-size: medium !important;
}



.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #0e4768 !important;
    color: #ffff !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #ffff
}

.doInput input {
    width: 100% !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    height: 38px !important;
    transition: border 0.3s !important;
    background: #ffff !important;
    border-radius: 3px !important;
}

.Do_Select {
    .p-dropdown {
        border-radius: 3px !important;

        .p-dropdown-label {
            display: flex;
            align-items: center;
            width: 100%;
            padding: auto;
            font-size: 12px;
            height: 38px;
            transition: border 0.3s;
            background: transparent;
            border-radius: 3px !important;
        }

    }
}

.p-multiselect {
    display: flex;
    align-items: center;
    width: 100%;
    padding: auto;
    font-size: 12px;
    height: 40px;
    transition: border 0.3s;
    background: transparent;
    border-radius: 3px !important;
}

.p-button.p-button-icon-only {
    height: 2.4rem !important;
}

.formmodal {
    width: 6rem !important;
    justify-content: center !important;

}

.p-dialog .p-dialog-header {
    height: 3.5rem !important;
    background-color: #0e4768 !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 0.8rem !important;
    height: 0.8rem !important;
    color: white !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: none !important;
}

.p-link:focus {
    box-shadow: none !important;
}

.p-button:focus {
    box-shadow: none !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 18px;
}

.p-datatable .p-datatable-tbody>td {
    text-align: center !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    padding: 8px !important;
}

.p-datatable .p-datatable-thead>tr>th {
    padding: 6px !important;
}

.modal__title {
    color: white !important;
}

.forgot_Password {
    width: 21rem !important;
    height: 2rem !important;
    justify-content: center !important;
}

.Back_login {
    margin-left: 15rem !important;
}

.sameFamNameCheckBox {
    justify-content: space-between;
    align-items: center;

    svg {
        margin-top: -4px;
    }
}

.cradBodres {
    border: 1px solid $color-template !important;
}

.addressDetailsBg {
    background-color: rgb(41 0 255 / 6%) !important;
}

// .addressDetailsBg1 {
//     background-color: rgba(0, 0, 255, .02) !important;
// }
.cradBodres {
    border: 1px solid $color-template !important;
}

.borderRadius5 {
    border-radius: 5px;
}

.align_fields {
    padding-right: 1rem !important;
}

.align_field {
    padding-left: 1rem !important;
}

.p-autocomplete .p-component .p-inputwrapper .p-autocomplete-dd .p-inputwrapper-filled {
    padding-right: 2rem !important;
}

.small-delete-modal .p-dialog-content {
    padding: 20px;
}

.small-delete-modal .p-dialog-header {
    display: none;
}

.small-delete-modal .p-dialog-footer {
    text-align: right;
}

.small-delete-modal .p-dialog-footer .p-button {
    margin-left: 8px;
}



.checkbox-container {
    display: flex;
    align-items: center;
}

.checkbox-label {
    margin-left: 0.5rem;
}

.input-group {
    display: flex;
    flex-wrap: nowrap;
}

.input-group .form-control {
    flex: 1;
}


.same-line {
    display: flex;
    align-items: center;
}

.same-line .checkbox-field {
    margin-right: 10px;
    /* Adjust the spacing as needed */
}

.d-flex {
    display: flex;
    align-items: center;
}

.ml-2 {
    margin-left: 8px;
    /* Adjust the value to your preference */
}

.p-dropdown {
    border-radius: unset !important;
}

.p-inputgroup input:last-child {
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
}

.p-inputgroup-addon:first-child {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
}

.paymentColor {
    color: $color-template !important;
    font-weight: 600;
}

.blackColor {
    color: black;
    font-weight: 500;
}

.userPwdResetModal .modal-content {
    width: 80%;
    padding: 35px 40px 25px 40px,
}

.userPwdResetModal .form {
    width: 400px;
    margin: auto;
}

.align_checkBox {
    justify-content: space-evenly !important;
}

.input-checkbox-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.checkbox-container {
    margin-top: 0.5rem;
}

.d-flex.align-items-center {
    display: flex;
    align-items: center;
}

.flex-grow-1 {
    flex-grow: 1;
}

.p-2 {
    padding: 0.5rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.p-dialog-mask.p-component-overlay {
    z-index: 300 !important;
}


.p-datatable .p-datatable-tbody>tr {
    color: #212529 !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2.5rem !important;
    height: 2.5rem !important;
    border-radius: unset !important;
}

body .p-component {
    font-family: Open Sans,
        Helvetica Neue,
        sans-serif;
    font-size: 14px;
}

.ViewModal_Font {
    font-weight: bold !important;
}

.align_AddStudent {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.custom-button-group .btn {
    margin: 0 10px;
    /* Adjust this value as needed to control the gap */
}

.family_buttons {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
}

.p-dialog .p-dialog-content {
    color: #495057 !important;
}

.userPwdResetModal .modal-content {
    padding: 35px 40px 25px 40px
}

.userPwdResetModal .form {
    width: 400px;
    margin: auto;
}

.p-checkbox .p-checkbox-box {
    border-radius: 0px !important;
}

.p-inputtext {
    border-radius: 0px !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2rem !important;
    height: 2rem !important;
    border-radius: none !important;
}

.align_radio_buttions {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.p-icon-field-right > .p-inputtext {
padding-right: 9rem;
}